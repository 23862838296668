// Cookies2.scss

$dialogWidth: 520px;
$dialogHeight: 367px;
$imageWidth: 137px;
$imageHeight: 87px;
$contentFontSize: 18px;
$buttonFontSize: 16px;

.signout-dialog {
  &-titles {
    margin: 0;
    padding: 2rem;
    width: $dialogWidth;
    height: $dialogHeight;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Adjusted alignment
    justify-content: center;

    img {
      width: $imageWidth;
      height: $imageHeight;
      object-fit: contain;
      margin-right: 20px; // Added margin to the right
    }

    h4 {
      margin-top: 0;
      font-weight: bold;
      margin-bottom: 10px; // Adjusted margin
    }

    p {
      color: #636880;
      font-family: Nunito;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: $contentFontSize;

    div {
      padding: 2rem;
      text-align: center;
    }
  }

  &-action {
    display: flex;
    justify-content: center;
    padding: 1rem;

    button {
      font-size: $buttonFontSize;
    }
  }
}

.typography {
  color: #636880;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .signout-dialog-title {
    width: 90%;
    align-items: center; 
    text-align: center;
    
    img {
      margin-right: 0; 
      margin-bottom: 10px; 
    }

    h4 {
      margin-bottom: 10px; 
    }
  }
}
@media screen and (max-width: 425px) {
  .signout-dialog-title {
    width: 90%;
    align-items: center; 
    text-align: center;
    
    img {
      margin-right: 0; 
      margin-bottom: 10px; 
    }

    h4 {
      margin-bottom: 5px; 
    }
  }
}
