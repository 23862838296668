.background-image2 {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
}

.three-images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.par-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondbackground {
  position: relative;
  width: 1681px;
  height: 1228px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .left {
    position: absolute;
    top: 40%;
    /* Adjust as needed for vertical positioning */
    left: 30%;
    /* Adjust as needed for horizontal positioning */
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Ensure the logo is above the background image */
    text-align: center;
  }

  .left img {
    width: 215px;
    height: 215px;
    flex-shrink: 0;
    border-radius: 50%;
  }


  h1 {
    -webkit-text-stroke-width: 7;
    -webkit-text-stroke-color: #000048;
    font-family: Rowdies;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px;
    margin-bottom: 0;
    margin-top: 0;
  }

  h2 {
    color: #000;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
    text-transform: uppercase;
    margin-top: 5px;
  }

  .para {
    width: 602px;
    margin-top: 1px;
  }
}

.boxes {
  display: flex;
  justify-content: center;
  align-items: center;
}


.boxnew {
  width: 200px;
  height: 50px;
  background-color: orange;
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.boxnew p {
  color: black;
  /* Optional: Set text color */
  margin: 15px;
  /* Remove default margin from the paragraph */
}

.images1 {
  position: absolute;
  top: 7%;
  right: 21%;
  width: 456.611px;
  height: 720.288px;
}

.images2 {
  position: absolute;
  top: -1%;
  right: 4%;
  width: 456.611px;
  height: 720.288px;
}

.images3 {
  position: absolute;
  top: 42%;
  right: 12%;
  width: 456.611px;
  height: 720.288px;
}

.images4 {
  position: absolute;
  top: 30%;
  right: 0%;

}


.thirdpart {

  h1 {
    color: #000;
    font-family: Nunito, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: 50px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 40px;
  }

  h2 {
    background: var(--adadad, linear-gradient(136deg, #00CFFF 16.44%, #00C5FF 65.22%, #00BAFF 92.76%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.2rem #000048;
    text-align: center;
    font-size: 80px;
    margin: 0;
  }
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.dots img {
  margin: 0 7px;
  /* Adjust the margin for less spacing */
}

.comments {
  display: flex;
  justify-content: center;
  position: relative;

  img {
    margin-top: 2%;
    margin-right: 40px;
    margin-left: 40px;
  }
}

.comments img:nth-child(2) {
  transform: scaleX(-1); // Mirror the second image horizontally
}

.comments img:nth-child(3) {
  transform: scaleX(-1); // Mirror the second image horizontally
}



.cmmntmsg1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  img {
    margin-top: -42%;
    margin-right: 21px;
    margin-left: 291px;
    height: 337px;
  }
}

.cmmntmsg2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  img {
    margin-top: -27%;
    margin-right: 40px;
    margin-left: 596px;
    height: 351px;
  }
}

.cmmntmsg3 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  img {
    margin-top: -21%;
    margin-right: 40px;
    margin-left: 963px;
    height: 337px;

  }
}

.cmmntmsg2 img {
  transform: scaleX(-1); // Mirror the second image horizontally
}

.cmmntmsg3 img {
  transform: scaleX(-1); // Mirror the second image horizontally
}


.leftheading h1 {
  background: var(--zcc, linear-gradient(322deg, #FFDC00 44.68%, #FFF47D 70.54%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.2rem #000048;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
}


.down-content-slider {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.slider-three {
  display: flex;
  overflow: hidden;
}

.slider-img {
  display: flex;
  transition: transform 0.5s ease;
  max-width: 100%;
}

.left-slidArrow,
.right-slidArrow {
  cursor: pointer;
  margin: 10px;
  display: flex;
}