.main4{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head4 {
  color: #262626;
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

}

.para4 {
  max-width: 90%;
  text-align: left;
  color: #636880;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

}



@media screen and (max-width: 768px) {
.para4{
  font-size: 20px;
  line-height: 35px;
}

}


@media screen and (max-width: 425px) {
.para4{
  font-size: 18px;
  line-height: 35px;
  text-align: center;
}

}