.main {
    width: 100%;
    height: 1676px;
    flex-shrink: 0;
    background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
}

.head h1 {
    color: #262626;
    text-align: center;
    font-family: Nunito;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 70px;
    text-transform: uppercase;
    margin-top: 25px;
}

.head-para {
    color: #262626;
    text-align: center;
    font-family: Calibri;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.box {
    gap: 53px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    align-items: center;
    margin-bottom: 0px;

}

.card {
    position: relative;
    /* Added for positioning the circle */
    width: 345px;
    height: 648px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1.5px solid white;
    background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.9119458198547363px);
    text-align: center;
    font-size: 15px;
    margin-top: 35px;

    /* Circle styles */
    &::before {
        content: '$00';
        position: absolute;
        top: 60px;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 90px;
        background-color: white;
        border-radius: 50%;
    }

    h2 {
        text-decoration: solid;
        margin-top: 170px;
        font-size: 25px;
    }

    p {
        color: #262626;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        // text-align: center;
        // align-items: center;
        // width: 303px;
    }

    .small-card {
        width: 345px;
        height: 124px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.15);
    }
}

.card2 {
    width: 345px;
    height: 648px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1.5px solid white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.9119458198547363px);
    text-align: center;
    font-size: 15px;
    margin-top: 35px;
    background-color: white;

    &::before {
        content: '$10';
        position: absolute;
        top: 60px;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 90px;
        background-color: rgb(0, 0, 0);
        border-radius: 50%;
    }

    h2 {
        text-decoration: solid;
        margin-top: 170px;
        font-size: 25px;
    }

    p {
        color: #262626;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .small-card2 {
        width: 344px;
        height: 163px;
        flex-shrink: 0;
        background: #00C6FF;
        text-align: left;
    }

}

.card3 {
    position: relative;
    /* Added for positioning the circle */
    width: 345px;
    height: 648px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1.5px solid white;
    background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.9119458198547363px);
    text-align: center;
    font-size: 15px;
    margin-top: 35px;

    /* Circle styles */
    &::before {
        content: '$00';
        position: absolute;
        top: 60px;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 90px;
        height: 90px;
        background-color: white;
        border-radius: 50%;
    }

    h2 {
        text-decoration: solid;
        margin-top: 170px;
        font-size: 25px;
    }

    p {
        color: #262626;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        // text-align: center;
        // align-items: center;
        // width: 303px;
    }

    .small-card3 {
        width: 345px;
        height: 163px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.15);
        text-align: left;
    }


}

.ending {
    text-align: left;
    margin-left: 15px;
}

.button {
    width: 160px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #F9F7F7;
    background: #00C6FF;
    cursor: pointer;
    margin-top: 10px;
}