$dialogWidth: 520px;
$dialogHeight: 367px;
$imageWidth: 137px;
$imageHeight: 87px;
$contentFontSize: 18px;
$buttonFontSize: 16px;

.signout-dialog {
  &-title {
    margin: 0;
    padding: 2rem;
    width: $dialogWidth;
    height: $dialogHeight;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: $imageWidth;
      height: $imageHeight;
      object-fit: contain; // Maintain aspect ratio
    }

    h3 {
      margin-top: 1rem;
      color: red;
      font-weight: bold;
    }
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: $contentFontSize;

    div {
      padding: 2rem;
      text-align: center;
    }
  }

  &-actions {
    display: flex;
    justify-content: center;
    padding: 1rem;

    button {
      font-size: $buttonFontSize;
    }
  }
}


.typography{
  color: #636880;
text-align: center;
font-family: Nunito;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px; 
text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .signout-dialog-title {
    width: 90%;
  }
}
