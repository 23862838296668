.first-background {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
  overflow: hidden;

}

.flexuu {
  display: flex;
}

.teddytale img {
  margin-top: 60px;
  margin-left: 80px;
  height: 240px;
  /* Adjusted height */
  width: auto;
  /* Maintain aspect ratio */
}

.message p {
  color: #000;
  font-family: Nunito;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px;
  text-transform: uppercase;
  margin-left: 100px;
}

.message-text p {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 80%;
  margin-left: 100px;
}

.playstore h4 {
  margin-left: 100px;
  margin-top: 60px;
  margin-bottom: 0px;
}

.playstore img {
  margin-left: 100px;
}

.teddy img {

  flex-shrink: 0;
  margin-top: 0px;
}

.boxer {
  gap: 53px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 0px;
}

.cards,
.cards3 {
  width: 138px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1.5px solid #00C6FF;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.9119458198547363px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-div {
  display: flex;
  align-items: center;
}

.card-div img {
  margin-right: 5px;
}

p {
  margin: 0;
}

b {
  font-weight: bold;
}

.popup img {
  width: 247px;
}


.head2 h2 {
  background: var(--adadad, linear-gradient(136deg, #00CFFF 16.44%, #00C5FF 65.22%, #00BAFF 92.76%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.3rem #000048;
  text-align: center;
  font-size: 80px;
}

.heading {
  text-align: center;
  margin-bottom: 5px;
}

.heading h4 {
  color: #000;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 50px;
  text-transform: uppercase;
  margin-top: 0px;
  width: 60% center;
}

.para {
  text-align: center;
}

.para p {
  color: #000;
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 160px;
  margin-right: 160px;
}


.leftright2 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 70px;
}

.left2 img {
  width: 520px;
}

.right2 {
  display: flex;
  justify-content: space-between;
}

.image-container {
  position: relative;
  width: 100%;
}

.right2 img {
  width: 100%;

}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: #000000;
}

p {
  color: #000;

  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}


.overlay-content h5,
.overlay-content p {
  margin: 0;
}

.right2t2 img {
  transform: rotate(180deg);
}









@media only screen and (max-width: 768px) {
  .first-background {
    height: auto;
    width: 163%;
    padding-bottom: 20px;
  }

  .leftside {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .teddytale img {
    margin-top: 20px;
    margin-left: 20px;
    height: auto;
    width: 80%;
  }

  .message p {
    font-size: 32px;
    margin-left: 20px;
  }

  .message-text p {
    margin-left: 20px;
    width: 90%;
  }

  .playstore h4,
  .playstore img {
    margin-left: 20px;
  }

  .popup img {
    width: 40%;
  }

  .rightside {
    flex-direction: column;
    align-items: center;
  }

  .teddy img {
    width: 100%;
    height: auto;
  }

  .boxer {
    margin-right: 100px;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .cards,
  .cards3 {
    width: 100%;
    margin-bottom: 10px;
  }

  .card-div img {
    margin-right: 3px;
  }

  .head2 h2 {
    font-size: 40px;
  }

  .heading h4 {
    font-size: 18px;
    width: 80%;
  }

  .para p {
    margin-left: 20px;
    margin-right: 20px;
  }

  .leftright2 {
    flex-direction: column;
  }

  .left2 img {
    width: 50%;
  }

  .right2 {
    flex-direction: column;
  }

  .overlay-content {
    text-align: center;
  }

  .right2 img {
    width: 50%;
  }

  .secondbackground .left {
    left: 30%;
    top: 40%;
  }

  .right {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1088px) {
  .flexuu {
    display: block;
  }

}

@media only screen and (max-width: 425px) {
  .first-background {
    height: auto;
    width: 300%;
    padding-bottom: 20px;
  }

  .secondbackground .left {
    max-width: 40%;
    left: 23%;
  }

  .leftheading {
    font-size: 40px;
  }

}