.main {
  width: 100%;
  min-height: 100vh;
  height: 976px;
  flex-shrink: 0;
  background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.head {
  margin: 25px 0;
}

h1 {
  color: #262626;
  text-align: center;
  font-family: Nunito;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
  text-transform: uppercase;
  margin-top: 25px;
}

.head-para {
  color: #262626;
  text-align: center;
  font-family: Calibri;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.box {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  position: relative;
  /* Added for positioning the circle */
  max-width: 345px;
  width: 100%;
  margin: 15px;
  height: 648px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1.5px solid white;
  background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.9119458198547363px);
  text-align: center;
  font-size: 15px;
  margin-top: 35px;
  position: relative;

  /* Circle styles */
  &::before {
    content: '$00';
    position: absolute;
    top: 60px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* Adjust the font size as needed */
    font-weight: bold;
  }


  h2 {
    text-decoration: solid;
    margin-top: 170px;
    font-size: 25px;
  }

  p {
    color: #262626;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    // text-align: center;
    // align-items: center;
    // width: 303px;
  }

  .small-card {
    width: 345px;
    height: 124px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.15);
  }
}

.card2 {
  width: 345px;
  height: 648px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1.5px solid white;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.9119458198547363px);
  text-align: center;
  font-size: 15px;
  margin-top: 35px;
  background-color: white;
  position: relative;

  .top-portion {
    width: 100%;
    height: 51px;
    flex-shrink: 0;
    background-color: #1CC600;
    border-radius: 1.5px solid #1CC600;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    color: #FFF;

    font-family: Calibri;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    text-align: left;

  }

  /* Circle styles */
  &::before {
    content: '$00';
    position: absolute;
    top: 60px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* Adjust the font size as needed */
    font-weight: bold;
  }


  h2 {
    text-decoration: solid;
    margin-top: 170px;
    font-size: 25px;
  }

  p {
    color: #262626;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .small-card2 {
    max-width: 344px;
    height: 163px;
    flex-shrink: 0;
    background: #00C6FF;
    text-align: left;
    margin: 0 auto;
  }

}

.card3 {
  position: relative;
  /* Added for positioning the circle */
  width: 345px;
  height: 648px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1.5px solid white;
  background: linear-gradient(0deg, #FCAF63 0%, #FCAF63 100%);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.9119458198547363px);
  text-align: center;
  font-size: 15px;
  margin-top: 35px;

  /* Circle styles */
  /* Circle styles */
  &::before {
    content: '$00';
    position: absolute;
    top: 60px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90px;
    height: 90px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* Adjust the font size as needed */
    font-weight: bold;
  }


  h2 {
    text-decoration: solid;
    margin-top: 170px;
    font-size: 25px;
  }

  p {
    color: #262626;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    // text-align: center;
    // align-items: center;
    // width: 303px;
  }

  .small-card3 {
    width: 345px;
    height: 163px;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.15);
    text-align: left;
  }


}

.ending {
  text-align: left;
  margin-left: 15px;
}

.button {
  width: 160px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #F9F7F7;
  background: #00C6FF;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .box {
    flex-direction: column;
  }

  .card,
  .card2,
  .card3 {
    margin: 0;
  }

  .small-card,
  .small-card2,
  .small-card3 {
    height: auto;
  }
}



// YourTableComponent.scss

.table-part {
  margin: 0 130px; // Adjust the spacing as needed

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
      padding: 10px;
      text-align: left;
      border: 1px solid #ddd;
    }

    th {
      background-color: #6b6969;
    }

    tbody {
      tr {
        &:hover {
          background-color: #f5f5f5;
        }

        td:first-child {
          input {
            margin-right: 5px;
          }
        }

        td:last-child {
          button {
            border-radius: 5px;
            border: 1px solid #f9f7f7;
            background: #00c6ff;
            color: white;
            cursor: pointer;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}