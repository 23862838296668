.signin {
  margin: 20px auto;
  border: 1px solid black;
  width: 90%;
  max-width: 570px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}

.cardd {
  text-align: center;
}

.threeLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.threebtns {
  background: rgba(255, 255, 255, 1);
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 410px;
  height: 44px;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
  flex-shrink: 0;
}

.threep {
  margin-left: 13px;
}

.checkbox {
  margin-top: 20px;
  height: 15px;
  flex-shrink: 0;
  border: 1px solid #ddd;
  background: #fff;
  width: 16px;
}

.labb {
  font-size: 12px; /* Adjust the font size as needed */
}

.termu {
  color: #29a0c2;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .signin {
    width: 90%;
    max-width: 100%;
  }
  
  .threebtns {
    width: 100%;
    max-width: none;
  }

  .labb {
    font-size: 10px;
  }
}
