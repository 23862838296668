.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo{
  display: flex;
  align-items: center;
}
.logo img{
  width: 76px;
  height: 76px;
  cursor: pointer ;
}


.navbar-items{
  display: flex;
  color: #000048;
font-family: Nunito, sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;  
cursor: pointer;
// margin-top: 10px;
}


.sign-in-button {
  color: #FFF;
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: #00C6FF;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
  // margin-top: 17px;
}
