.faq {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .MuiAccordion-root {
    margin-bottom: 15px;

    .MuiAccordionSummary-root {
      padding: 10px;
    }

    .MuiAccordionDetails-root {
      padding: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .faq {
    padding: 10px;

    h1 {
      font-size: 24px;
    }

    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        padding: 8px;
      }

      .MuiAccordionDetails-root {
        padding: 8px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .faq {
    h1 {
      font-size: 20px;
    }
  }
}
