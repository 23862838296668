$buttonWidth: 503px;
$buttonHeight: 55px;

.signout-dialog {
  &-title {
    // ... (existing styles)

    button {
      width: $buttonWidth;
      height: $buttonHeight;
      flex-shrink: 0;
      border-radius: 10px;
      background: #00C6FF;
      color: #FFF;
      font-size: 16px;
      cursor: pointer;
      border: none;
    }
  }
}
